import React, {useState, useEffect} from "react";
import { Container } from "react-bootstrap";
import "./omoss.css";
import "./overskrift.css";
import client from "../client";
import { Spinner } from "react-bootstrap";

const OmOss = () => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [forsidebilde, setForsideBilde] = useState(undefined)

  useEffect(() => {
    setLoading(true);
    client
      .fetch(
        `*[_type == 'omoss'] {title, body, email, "imageUrl": mainImage.asset->url}`
      )
      .then((res) => {
        setData(res);
        setLoading(false);
        setError("");
      })
      .catch((error) => {
        setError("Klarte ikke å hente dataen");
        setLoading(false);
      });
      client
      .fetch(
        `*[_type == 'forsidebilde'] {"forsideUrl": mainImage.asset->url}`
      )
      .then((res) => {
        setForsideBilde(res[0].forsideUrl)
      })
      .catch((error) => {
        setError("Klarte ikke å hente dataen");
        setLoading(false);
      });
  }, []);
  return (
    <>
      <div className="banner">
        <div className="imgWrapper">
          <img alt="utsikt" className="lonena" src={forsidebilde} />
        </div>
        
      </div>
      <Container
        className="d-flex justify-content-center"
        style={{ minHeight: "100vh" }}
      >
        <div className="w-100" style={{ maxWidth: "40rem" }}>
        <div className="banner__overlay">
          <h1>
            <span className="highLight">Velkommen til</span>
            <br />
            Sameiet KnaZen
          </h1>
        </div>
          <div>
            {!loading && !error && data  ? (
              <div>
                {data[0].body.map((elem, idx) => (
                  <p key={idx}>
                    {
                  elem.children.map(child => (
                    child.text
                  ))}
                  </p>
                ))}
              <img className="knazen-bygg" src={data[0].imageUrl} alt="Knazen" />
              </div>
            ) : error ? (
              <Spinner animation="border" role="status"></Spinner>
            ) : (
              <p>{error}</p>
            )}
          </div>
        </div>
      </Container>
    </>
  );
};

export default OmOss;
