import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "./overskrift.css";
import client from "../client";
import { Spinner } from "react-bootstrap";

const PraktiskInfo = () => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    client
      .fetch(
        `*[_type == 'praktiskinfo'] {title, body, filnavn, "imageUrl": mainImage.asset->url, "manuscriptURL": file.asset->url}`
      )
      .then((res) => {
        setData(res);
        setLoading(false);
        setError("");
      })
      .catch((error) => {
        setError("Klarte ikke å hente dataen");
        setLoading(false);
      });
  }, []);

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "60vh" }}
    >
      <div className="w-100" style={{ maxWidth: "40rem" }}>
        <h1 className="overskrift">Praktisk informasjon</h1>
        {!loading && !error && data ? (
          data ? (
            data.map((p, idx) => (
              <div className="post" key={idx}>
                <h4>{p.title && p.title}</h4>
                <div>
                  {p.body[0].children[0].text &&
                    p.body[0].children.map((child, idx) => child.text)}
                </div>
                {p.imageUrl && (
                  <img
                    className="post-image"
                    alt={p.title}
                    src={p.imageUrl}
                  ></img>
                )}
                {p.manuscriptURL && (
                  <a
                    href={p.manuscriptURL}
                    download="HMS-bok"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {p.filnavn}
                  </a>
                )}
              </div>
            ))
          ) : (
            <p>Ingen nyheter</p>
          )
        ) : error ? (
          <Spinner animation="border" role="status"></Spinner>
        ) : (
          <p>{error}</p>
        )}
      </div>
    </Container>
  );
};

export default PraktiskInfo;
