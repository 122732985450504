import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "./Ordensregler.css";
import client from "../client";
import "./overskrift.css";
import { Spinner } from "react-bootstrap";

const OrdensRegler = () => {
  const [tempContent, setTempContent] = useState([]);
  const [innhold, setInnhold] = useState([]);

  const [formål, setFormål] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    let i = [...tempContent];

    let f = i.filter((t) => t[0] === "Formål");
    f = f[0] ? f[0] : undefined;
    i = i.filter((t) => t[0] !== "Formål");
    setInnhold(i);
    setFormål(f);
  }, [tempContent]);

  useEffect(() => {
    setLoading(true);
    client
      .fetch("*[_type == 'ordensregler']")
      .then((res) =>
        res.map((elem) => {
          const title = elem.title;
          const body = elem.body[0].children[0].text;
          return [title, body];
        })
      )
      .then((res) => {
        setTempContent(res);
        setLoading(false);
        setError("");
      })
      .catch((error) => {
        setLoading(false);
        setError("Klarte ikke å hente dataen");
      });
  }, []);

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "100vh" }}
    >
      <div className="w-100" style={{ maxWidth: "40rem" }}>
        <h1 className="overskrift">Ordensregler</h1>
        {!loading && !error && innhold ? (
          <ol>
            <li>
              <p>
                {formål && formål[0]}
                <br />
                {formål && formål[1]}
              </p>
            </li>
            {innhold.map((text, idx) => (
              <li key={idx}>
                <p>
                  {text[0]}
                  <br />
                  {text[1]}
                </p>
              </li>
            ))}
          </ol>
        ) : error ? (
          <Spinner animation="border" role="status"></Spinner>
        ) : (
          <p>{error}</p>
        )}
      </div>
    </Container>
  );
};

export default OrdensRegler;
