import moment from "moment";
import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import client from "../client";
import "./nyheter.css";
import "./overskrift.css";
import { Spinner } from "react-bootstrap";

const Nyheter = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    client
      .fetch(
        `*[_type == 'news'] | order(publiseringsdato) {title, body, "imageUrl": mainImage.asset->url, publiseringsdato}`
      )
      .then((res) => {
        setPosts(res.reverse());
        setLoading(false);
        setError("");
      })
      .catch((error) => {
        setError("Klarte ikke å hente dataen");
        setLoading(false);
      });
  }, []);

  const visDato = (dato) => {
    return moment(dato).format("LLL");
  };
  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "60vh" }}
    >
      <div className="w-100" style={{ maxWidth: "40rem" }}>
        <h1 className="overskrift">Nyheter</h1>
        {!loading && !error && posts ? (
          posts.length > 0 ? (
            posts.map((p, idx) => (
              <div className="post" key={idx}>
                <h4>{p.title && p.title}</h4>
                <div>
                  {p.body[0].children[0].text &&
                    p.body[0].children.map((child, idx) => child.text)}
                </div>
                {p.imageUrl && (
                  <img
                    className="post-image"
                    alt={p.title}
                    src={p.imageUrl}
                  ></img>
                )}
                <p className="publiseringsdato">
                  {visDato(p.publiseringsdato)}
                </p>
              </div>
            ))
          ) : (
            <p>Ingen nyheter for øyeblikket</p>
          )
        ) : error ? (
            <Spinner animation="border" role="status"></Spinner>
        ) : <p>{error}</p>}
      </div>
    </Container>
  );
};

export default Nyheter;
