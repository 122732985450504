import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Header from "./Header";
import PraktiskInfo from "./PraktiskInfo";
import OmOss from "./OmOss";
import KontaktOss from "./KontaktOss";
import OrdensRegler from "./OrdensRegler";
import "./omoss.css";
import KnazenMaps from "../filer/knazen-maps.jpg"
import Nyheter from "./Nyheter";
import client from "../client";

function App() {

  const [email, setEmail] = useState("")

  useEffect(() => {
    client
      .fetch(`*[_type == 'kontaktoss'] {email}`)
      .then((res) => {
        setEmail(res[0].email);
      })
      .catch((error) => {

      });
  }, []);

  return (
      <Router className="router">
          <Route path="/" component={() => <Header />} />
          <Switch>
            <Route exact path="/" component={OmOss} />
            <Route exact path="/nyheter" component={Nyheter} />
            <Route exact path="/kontakt Oss" component={KontaktOss} />
            <Route
              exact
              path="/praktisk informasjon"
              component={PraktiskInfo}
            />
            <Route path="/ordensregler" component={OrdensRegler} />
          </Switch>
        <footer>
          <div className="footer-content">
            <div className="footer-element">
              <h3>Kontaktinfo:</h3>
              <p>
                Email:
                <br />
                <a href = {`mailto: ${email}`}>
                {email}
                </a>
              </p>
            </div>
            <div className="footer-element">
              <h3>Snarveier</h3>
              <Link to={`/`} className="footer-link">
                Om Oss
              </Link>
              <Link to={`/kontakt oss`} className="footer-link">
                Kontakt Oss
              </Link>
              <Link to={`/praktisk informasjon`} className="footer-link">
                Praktisk informasjon
              </Link>
              <Link to={`/ordensregler`} className="footer-link">
                Ordensregler
              </Link>
              <p></p>
            </div>
            <div className="footer-element">
              <a target="_blank" rel="noopener noreferrer" href="https://www.google.com/maps/place/Langheiane+79,+5914+Isdalst%C3%B8,+Norway/@60.5531977,5.2887406,16.08z/data=!4m5!3m4!1s0x463d039c28d6ebb5:0x88eaa7512f7a101d!8m2!3d60.553225!4d5.2918091"> 
              <img src={KnazenMaps} className="map-img" alt="Kart"></img>
              </a>
            </div>
          </div>
        </footer>
      </Router>
  );
}

export default App;
