import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "./overskrift.css";
import client from "../client";
import { Spinner } from "react-bootstrap";

const KontaktOss = () => {
  const [data, setData] = useState(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    setLoading(true);
    client
      .fetch(`*[_type == 'kontaktoss'] {title, body, email}`)
      .then((res) => {
        setData(res);
        setLoading(false);
        setError("");
      })
      .catch((error) => {
        setError("Klarte ikke å hente dataen");
        setLoading(false);
      });
  }, []);

  return (
    <Container
      className="d-flex justify-content-center"
      style={{ minHeight: "60vh" }}
    >
      <div className="w-100" style={{ maxWidth: "40rem" }}>
        <h1 className="overskrift">Kontakt oss</h1>
        {!loading && data && !error ? (
          <div>
            {data[0].body.map((elem, idx) => (
                  <p key={idx}>
                    {
                  elem.children.map(child => (
                    child.text
                  ))}
                  </p>
                ))}
            {data[0].email && <a href={`mailto: ${data[0].email}`}>{data[0].email}</a>}
          </div>
        ) : error ? (
          <Spinner animation="border" role="status"></Spinner>
        ) : (
          <p>{error}</p>
        )}
      </div>
    </Container>
  );
};

export default KontaktOss;
