import { Link, useParams } from "react-router-dom";
import React from "react";
import "./Header.css";


const HeaderLink = ({ page }) => {
  const title = page.charAt(0).toUpperCase() + page.slice(1);

  return (
    <Link to={`/${page}`} className="headerlink-title">
        <div></div>
      {title ? title : "Om Oss"}
    </Link>
  );
};

const Header = () => {
  const { page } = useParams();
  return (
    <div className="header">
      <HeaderLink page="" selected={page === "omoss" }/>
      <HeaderLink page="nyheter" selected={page === "nyheter" }/>
      <HeaderLink page="praktisk informasjon" selected={page === "praktisk info"} />
      <HeaderLink page="ordensregler" selected={page === "ordensregler"} />
      <HeaderLink page="kontakt oss" selected={page === "kontakt oss"} />
    </div>
  );
};
export default Header;
